<template>
  <div>

    <div class="flex mb-10 items-center">
      <div class="flex items-center justify-center w-full">
        {{ description }}
      </div>
    </div>

    <div class="mb-5" v-if="showTeachers">
      <h5 class="mb-3"> {{ $t('professores') }} </h5>
      <vs-table :data="teachers">
        <template slot="thead">
          <vs-th width="30%">{{ $t('name') }}</vs-th>
          <vs-th width="40%">{{ $t('email') }}</vs-th>
          <vs-th width="30%">{{ $t('discipline') }}</vs-th>              
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="data[i]" v-for="(tr, i) in data" :style="rowStyle(data[i])">
            <vs-td>
              {{ (data[i].who) ? '('+$t('Me')+') ' : null}}
              {{data[i].name}}
            </vs-td>
            <vs-td>
              {{data[i].email}}
            </vs-td>
            <vs-td>                  
              {{data[i].discipline_title}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div class="mb-5" v-if="showStudents">
      <h5 class="mb-3">{{ $t('students') }}</h5>
      <vs-table :data="students">
        <template slot="thead">
          <vs-th width="30%">{{ $t('name') }}</vs-th>
          <vs-th width="70%">{{ $t('email') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="data[i]" v-for="(tr, i) in data" :style="rowStyle(data[i])">
            <vs-td>
              {{ (data[i].who) ? '('+$t('Me')+') ' : null}}
              {{data[i].name}}
            </vs-td>
            <vs-td>
              {{data[i].email}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    rowStyle(entry) {
      if (entry.who === 'me') return 'color: #ff6000'
    }
  },
  props: {
    model: {
      type: Object,
      default: null
    },
    people: {
      type: Object,
      default: null
    },
    showTeachers: {
      type: Boolean,
      default: false
    },
    showStudents: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title() {
      return _.get(this.model, 'title') || ''
    },
    description() {
      return _.get(this.model, 'description') || ''
    },
    students() {
      return _.get(this.people, 'students') || []
    },
    teachers() {
      return _.get(this.people, 'teachers') || []
    }
  }
}
</script>

<style>

</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "w-full mb-4" },
        [
          _c(
            "vs-button",
            {
              attrs: { icon: "undo", type: "border" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/classes_students")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "flex mt-6 items-center" }, [
            _c(
              "div",
              { staticClass: "flex items-center justify-center w-full" },
              [_c("h1", [_vm._v(_vm._s(_vm.title))])]
            ),
          ]),
          _c(
            "vs-tabs",
            {
              key: _vm.activeTab,
              staticClass: "pt-4",
              attrs: { alignment: "center" },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _vm.contentsCount > 0
                ? _c(
                    "vs-tab",
                    { attrs: { label: _vm.$t("Contents") } },
                    [
                      _c("content-list", {
                        attrs: {
                          "class-model": _vm.classData,
                          id: _vm.class_id,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("detalhes") } },
                [
                  _c("class-details", {
                    attrs: {
                      "show-teachers": _vm._.get(
                        _vm.classData,
                        "show_teachers"
                      ),
                      "show-students": _vm._.get(
                        _vm.classData,
                        "show_students"
                      ),
                      people: _vm.classPeopleData,
                      model: _vm.classData,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <!--
    IMPORTANT COMMENT -  READ ME

    This component is only used to expose/fetch some content's media on the fly,
    currently only used on ContentList.vue.
  -->

  <div>
    <slot :medias="medias">
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      default: null
    },
    id: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    medias: []
  }),

  mounted() {
    if (this.service && this.id) {
      this.service.getMedias(this.id).then(
        response => {
          if (this._.isArray(response) && response.length > 0) {
            this.medias = response
          } else {
            this.medias = []
          }
        },
        error => {
        }
      )
    }
  }
}
</script>

<style>

</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "vx-card",
        {
          staticClass:
            "_content-item-card w-auto shadow-drop border-solid border-grey border mb-4",
          style:
            "--expanded: " +
            (_vm.expanded
              ? "var(--background-invert)"
              : "var(--background-normal)"),
        },
        [
          _c(
            "div",
            {
              staticClass: "__content-item",
              on: {
                click: function ($event) {
                  return _vm.toggleExpansion(_vm.item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.type === "A"
                      ? "border-warning bg-warning"
                      : "border-success bg-success",
                    "_content-bubble-icon flex items-center justify-center",
                  ],
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "h-5 w-5 m-auto",
                    attrs: { icon: "book" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "__title" }, [
                _c("span", [_vm._v(_vm._s(_vm._.get(_vm.item, "title")))]),
              ]),
              _c("div", { staticClass: "__status" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.getContentEngagementStatus(_vm.item))),
                ]),
              ]),
              _c(
                "div",
                { ref: "act" + _vm.i, staticClass: "__actions" },
                [
                  _c(
                    "vs-dropdown",
                    { staticClass: "cursor-pointer hover:text-primary" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("feather-icon", {
                            staticClass: "h-5 w-5",
                            attrs: { type: "filled", icon: "MoreVerticalIcon" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c("vs-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex gap-1 items-center hover:text-primary cursor-pointer",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openContent(_vm.item)
                                  },
                                },
                              },
                              [
                                _c("vs-icon", {
                                  attrs: { size: "1.5rem", icon: "visibility" },
                                }),
                                _c("span", [_vm._v(_vm._s(_vm.$t("abrir")))]),
                              ],
                              1
                            ),
                          ]),
                          _c("vs-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex gap-1 items-center hover:text-primary cursor-pointer",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.copyLink(
                                      _vm.item,
                                      _vm.i,
                                      "act" + _vm.i
                                    )
                                  },
                                },
                              },
                              [
                                _c("vs-icon", {
                                  attrs: { size: "1.5rem", icon: "link" },
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("copiar-link"))),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "__overlay" }, [
                _c("div", { staticClass: "__timestamps" }, [
                  _c("div"),
                  _c("div", { staticClass: "text-sm" }, [
                    _c("label", { staticClass: "font-medium pr-2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("liberado-em")) + " "),
                    ]),
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$utils.format.date(
                              _vm._.get(_vm.item, "release_at")
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "transition",
            {
              attrs: { name: "fade-top" },
              on: {
                "after-enter": function ($event) {
                  return _vm.itemExpanded(_vm.theme, $event, _vm.item, _vm.i)
                },
              },
            },
            [
              _vm.expanded
                ? _c("div", { staticClass: "__content-item-expansion" }, [
                    _c("div", { staticClass: "__instructions-html" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value: _vm._.get(_vm.item, "instructions", ""),
                            expression: "_.get(item, 'instructions', '')",
                          },
                        ],
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "__media-list" },
                      [
                        _c("vs-divider", { staticClass: "w-full" }),
                        _c("content-media-getter", {
                          attrs: {
                            id: _vm._.get(_vm.item, "id"),
                            service: _vm.service,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (getter) {
                                  return [
                                    _c("content-media-list", {
                                      attrs: {
                                        medias: getter.medias,
                                        "show-delete": false,
                                        "action-on-click": true,
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            280877743
                          ),
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
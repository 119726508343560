<template>
  <vx-card v-if="item"
    class="_content-item-card w-auto shadow-drop border-solid border-grey border mb-4"
    :style="`--expanded: ${expanded ? 'var(--background-invert)' : 'var(--background-normal)'}`"
  >
    <div @click="toggleExpansion(item)" class="__content-item">
      <div :class="[type === 'A' ? 'border-warning bg-warning' : 'border-success bg-success', '_content-bubble-icon flex items-center justify-center']">
        <font-awesome-icon
          icon="book"
          class="h-5 w-5 m-auto"
        />
      </div>
      <div class="__title">
        <span>{{ _.get(item, 'title') }}</span>
      </div>
      <div class="__status">
        <span>{{ getContentEngagementStatus(item) }}</span>
      </div>
      <div class="__actions" :ref="`act${i}`">
        <vs-dropdown class="cursor-pointer hover:text-primary">
          <div class="flex items-center">
            <feather-icon
              class="h-5 w-5"
              type="filled"
              icon="MoreVerticalIcon">
            </feather-icon>
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item>
              <div
                @click.stop="openContent(item)"
                class="flex gap-1 items-center hover:text-primary cursor-pointer"
              >
                <vs-icon size="1.5rem" icon="visibility"/>
                <span>{{ $t('abrir') }}</span>
              </div>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <div
                @click.stop="copyLink(item, i, `act${i}`)"
                class="flex gap-1 items-center hover:text-primary cursor-pointer"
              >
                <vs-icon size="1.5rem" icon="link"/>
                <span>{{ $t('copiar-link') }}</span>
              </div>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <div class="__overlay">
        <div class="__timestamps">
          <div>
            <!-- DUMMY -->
          </div>
          <div class="text-sm">
            <label class="font-medium pr-2">
              {{ $t('liberado-em') }}
            </label>
            <label>
              {{ $utils.format.date(_.get(item, 'release_at')) }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <transition @after-enter="itemExpanded(theme, $event, item, i)" name="fade-top">
      <div v-if="expanded" class="__content-item-expansion">
        <div class="__instructions-html">
          <div v-html-safe="_.get(item, 'instructions', '')"/>
        </div>
        <div class="__media-list">
          <vs-divider class="w-full"/>
          <content-media-getter :id="_.get(item, 'id')" :service="service">
            <template v-slot:default="getter">
              <content-media-list :medias="getter.medias" :show-delete="false" :action-on-click="true"/>
            </template>
          </content-media-getter>
        </div>
      </div>
    </transition>
  </vx-card>
</template>

<script>
import ContentService from '@/services/api/ContentService'
import ContentMediaGetter from './ContentMediaGetter.vue'
import ContentMediaList from '../ContentMediaList.vue'
import ContentLink from '@/mixins/ContentLink.js'
export default {
  components: { ContentMediaGetter, ContentMediaList },
  mixins: [
    ContentLink
  ],
  props: {
    // Under what class is this content being shown?
    classId: {
      default: null,
      type: Number
    },
    expanded: {
      default: false,
      type: Boolean
    },
    item: {
      default: null,
      type: Object
    },
    service: {
      default: () => ContentService.build(),
      type: Object
    }
  },
  computed: {
    type() {
      return _.get(this.item, 'type', false) || false
    }
  },
  methods: {
    openContent(content) {
      const link = this.getUrl(content)
      const fromAccess = this._.get(content, 'from_access')
      console.log('opening link', link, 'and has from access', fromAccess)
      if (link) {
        this.$utils.browser.newTab(`${link}?a=${fromAccess}`)
      }
    },
    toggleExpansion(content, theme) {
      this.$emit('toggleExpansion', content, theme)
    },
    getContentEngagementStatus(content) {
      const [status, time]  = _.get(content, 'my_engagement', []) || []
      switch (status) {
      case 'pending':
        return this.$t('pendente')
      case 'viewed':
        return this.$t('visualizado-this-utils-format-date-time', [this.$utils.format.date(time)])
      }
    },
    itemExpanded() {
      this.$emit('itemExpanded', true)
    }

  },
}
</script>

<style lang="scss">
// Import _content-bubble-icon
@import "@/assets/scss/content/bubbleIcon";

._content-item-card {
  cursor: auto !important;

  & .vx-card__body {
    padding: 0 !important;
  }

  & .__content-item {
    @apply flex;
    @apply flex-row;
    @apply items-center;
    @apply gap-2;
    background-color: var(--expanded);

    &:hover {
      background-color: var(--background-invert);
      @apply cursor-pointer;
    }

    & .__title {
      @apply flex-grow;
      @apply mr-auto;
      @apply font-medium;
      @apply overflow-hidden;
    }
    & .__status {
      @apply flex-grow;
      @apply flex;
      @apply items-end;
      @apply justify-end;
      @apply pr-2;
      max-width: 10rem;
      @apply select-none;
    }
    & .__actions {
      z-index: 2;
      @apply flex;
      @apply items-center;
      @apply justify-end;
      @apply gap-3;
      @apply px-2;
      // max-width: 5rem;
    }

    & .__overlay {
      @apply w-full;
      @apply p-1;
      position: absolute;
      top: 2.95rem;
      left: -0.5rem;
      & .__timestamps {
        @apply flex;
        @apply w-full;
        @apply gap-2;
        @apply justify-between;
        @apply pb-3;
      }
    }
  }

  & .__content-item-expansion {
    min-height: 4rem;
    padding: 0.25rem 1rem 0.35rem 1rem;

    & .__instructions-html {
      @apply break-words;
      max-width: 70vw;
    }
  }

}



</style>
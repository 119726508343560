
<template>
  <div>
      <div class="w-full mb-4">
        <vs-button icon="undo" type="border" @click="$router.push('/classes_students')">
        {{ $t('common.back') }}
        </vs-button>
      </div>

      <vx-card>
        <div class="flex mt-6 items-center">
          <div class="flex items-center justify-center w-full">
            <h1>{{ title }}</h1>
          </div>
        </div>
        <vs-tabs :key="activeTab" v-model="activeTab" class="pt-4" alignment="center">
          <vs-tab v-if="contentsCount > 0" :label="$t('Contents')">
            <content-list :class-model="classData" :id="class_id"/>
          </vs-tab>
          <vs-tab :label="$t('detalhes')">
            <class-details
              :show-teachers="_.get(classData, 'show_teachers')"
              :show-students="_.get(classData, 'show_students')"
              :people="classPeopleData"
              :model="classData"
            />
          </vs-tab>
        </vs-tabs>
      </vx-card>

  </div>
</template>

<script>
import ClassService from '@/services/api/ClassService'
import ClassDetails from './ClassDetails.vue'
import ContentList from '../../contents/view/ContentList.vue'

export default {
  components: { ClassDetails, ContentList },
  props: {
    class_id : {
      type: Number,
      default: null
    }
  },
  data: () => ({
    activeTab: 0,
    classService: null,
    classPeopleData: null,
    classData: null
  }),
  computed: {
    // PASS
    title() {
      return _.get(this.classData, 'title')
    },
    contentsCount() {
      return _.get(this.classData, 'contents_count') || 0
    }
  },
  methods: {
    // PASS
  },
  mounted() {
    setTimeout(() => {
      const tab = _.get(this.$route, 'query.t', 'details')

      this.activeTab = _.get({
        'details': 0,
        'contents': 1
      }, tab, 0)
    }, 500)
  },
  beforeMount() {
    this.classService = ClassService.build(this.$vs)
    this.$vs.loading()

    this.classService.read(this.class_id).then(
      data => {
        this.$set(this.$data, 'classData', data)
        this.classService.fetchPeople(this.class_id).then(
          data => {
            this.$set(this.$data, 'classPeopleData', data)
            this.$vs.loading.close()
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )

      },
      error => {
        this.$vs.loading.close()
        this.showErrors(this.$validator, error)
        this.notifyError(
          this.$vs,
          this.$t('nao-foi-possivel-realizar-esta-operacao')
        )
      }
    )

  }
}
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "_content-list" }, [
    _c("div", { staticClass: "__top" }, [
      _c("div", { staticClass: "flex gap-2 flex-grow-0" }, [
        _c(
          "div",
          {
            class: [
              "flex ml-2 cursor-pointer",
              _vm.type === "M" ? "edu-switch-selected" : "edu-switch",
            ],
            on: {
              click: function ($event) {
                _vm.type = "M"
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "h-6 w-6 text-success",
              attrs: { icon: "book-reader" },
            }),
            _c("span", { staticClass: "ml-2 text-success" }, [
              _vm._v(_vm._s(_vm.$t("content_types.M"))),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            class: [
              "flex cursor-pointer",
              _vm.type === "A" ? "edu-switch-selected" : "edu-switch",
            ],
            on: {
              click: function ($event) {
                _vm.type = "A"
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "h-6 w-6 text-warning",
              attrs: { icon: "file-alt" },
            }),
            _c("span", { staticClass: "ml-2 text-warning" }, [
              _vm._v(_vm._s(_vm.$t("content_types.A"))),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "ml-auto" },
        [
          _c("vs-input", {
            staticClass: "searchbar",
            attrs: { icon: "search" },
            model: {
              value: _vm.searchInput,
              callback: function ($$v) {
                _vm.searchInput = $$v
              },
              expression: "searchInput",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "__bottom" }, [
      _c(
        "div",
        { staticClass: "__bottom-grid" },
        [
          false ? _c("div", { staticClass: "__filters" }) : _vm._e(),
          _vm.filteredContents.length == 0
            ? _c("div", [
                _c("div", { staticClass: "w-full flex justify-center" }, [
                  _c("label", { staticClass: "font-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.type === "M"
                            ? "Nenhum material foi encontrado."
                            : "Nenhuma atividade foi encontrada."
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.themelessCount > 0
            ? _c(
                "div",
                { staticClass: "__list pb-4" },
                _vm._l(_vm.groupedContents.themeless.none, function (item) {
                  return _c("content-item", {
                    key: item,
                    attrs: {
                      "class-id": _vm.classId,
                      item: item,
                      expanded: _vm.isExpanded(item),
                    },
                    on: { toggleExpansion: _vm.toggleExpansion },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.themedCount > 0
            ? _c(
                "vs-collapse",
                { staticClass: "__list", attrs: { type: "border" } },
                _vm._l(_vm.groupedContents.themed, function (items, theme) {
                  return _c(
                    "vs-collapse-item",
                    { key: theme, ref: "collapse_" + theme, refInFor: true },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex gap-2 items-center select-none cursor-pointer",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "_content-bubble-icon bg-primary flex items-center justify-center",
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "h-5 w-5 m-auto",
                                  attrs: { icon: "bookmark" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex flex-grow flex-col" },
                              [
                                _c("span", { staticClass: "w-full" }, [
                                  _vm._v(_vm._s(theme)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._l(items, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "__themed" },
                          [
                            _c("content-item", {
                              attrs: {
                                "class-id": _vm.classId,
                                item: item,
                                expanded: _vm.isExpanded(item),
                              },
                              on: {
                                toggleExpansion: _vm.toggleExpansion,
                                itemExpanded: function () {
                                  return _vm.forceThemeCollapseUpdate(theme)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import i18n from '@/i18n/i18n'

export default {
  methods: {
    copyLink(content, i, ref) {
      console.log('trying to copy something!', content, i, ref)
      const element = this.$refs[ref]//`act${i}`]
      const text = this.getUrl(content)

      if (text && element) {
        this.$copyText(text, element.$el).then(
          event => {
            this.copySuccess()
          },
          event => {
            this.copyError()
          }
        )
      }
    },
    copyError() {
      this.notifyError(this.$vs, i18n.t('nao-foi-possivel-copiar'))
    },
    copySuccess() {
      this.notifySuccess(this.$vs, `${i18n.t('copiado')}!`, 2000, 'center-top')
    },
    getUrl(content) {
      const id = _.get(content, 'id')
      if (id) {
        const origin = window.location.origin
        const path = `${origin}/#/contents/${id}`
        return path
      }
    },
  }
}
<template>
  <div class="_content-list">
    <div class="__top">
      <div class="flex gap-2 flex-grow-0">
        <div @click="type='M'" :class="['flex ml-2 cursor-pointer', type === 'M' ? 'edu-switch-selected' : 'edu-switch']">
          <font-awesome-icon icon="book-reader" class="h-6 w-6 text-success"/>
          <span class="ml-2 text-success">{{ $t('content_types.M') }}</span>
        </div>
        <div @click="type='A'" :class="['flex cursor-pointer', type === 'A' ? 'edu-switch-selected' : 'edu-switch']">
          <font-awesome-icon icon="file-alt" class="h-6 w-6 text-warning"/>
          <span class="ml-2 text-warning">{{ $t('content_types.A') }}</span>
        </div>
      </div>
      <div class="ml-auto">
        <vs-input icon="search" v-model="searchInput" class="searchbar">
        </vs-input>
      </div>
    </div>
    <div class="__bottom">
      <div class="__bottom-grid">

        <!-- FILTERS -->
        <div v-if="false" class="__filters">
          <!-- TODO IMPLEMENT FILTERS -->
        </div>

        <!-- NO CONTENT NOTICE/INFO -->
        <div v-if="filteredContents.length == 0">
          <div class="w-full flex justify-center">
            <label class="font-bold">
              {{
                type === 'M' ?
                'Nenhum material foi encontrado.' :
                'Nenhuma atividade foi encontrada.'
              }}
            </label>
          </div>
        </div>

        <!-- Themeless Content List -->
        <div class="__list pb-4" v-if="themelessCount > 0">
          <content-item
            :class-id="classId"
            :key="item"
            v-for="item in groupedContents.themeless.none"
            :item="item"
            :expanded="isExpanded(item)"
            @toggleExpansion="toggleExpansion"
          >
          </content-item>
        </div>

        <!-- Contents with theme list -->
        <vs-collapse type="border" v-if="themedCount > 0" class="__list">
          <vs-collapse-item :ref="`collapse_${theme}`" :key="theme" v-for="(items, theme) in groupedContents.themed">
            <div slot="header">
              <div class="flex gap-2 items-center select-none cursor-pointer">
                <div class="_content-bubble-icon bg-primary flex items-center justify-center">
                  <font-awesome-icon
                    icon="bookmark"
                    class="h-5 w-5 m-auto"
                  />
                </div>
                <div class="flex flex-grow flex-col">
                  <span class="w-full">{{ theme }}</span>
                </div>
              </div>
            </div>
            <div class="__themed" :key="i" v-for="(item, i) in items">
              <content-item
                :class-id="classId"
                :item="item"
                :expanded="isExpanded(item)" 
                @toggleExpansion="toggleExpansion"
                @itemExpanded="() => forceThemeCollapseUpdate(theme)"
              />
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import ContentService from '@/services/api/ContentService'
import {ilike} from '@/util/RegularExpressions'
import ContentMediaList from '../ContentMediaList.vue'
import ContentItem from './ContentItem.vue'
export default {
  components: { ContentMediaList, ContentItem },
  props: {
    // The class ID.
    id: {
      default: null,
      type: Number
    },
    classModel: {
      default: null,
      type: Object
    }
  },
  data: () => ({
    contents: [],
    searchInput: null,
    service: null,
    classService: null,
    type: 'M',
    expandedContents: [],
  }),
  computed: {
    classId() {
      return _.get(this.classModel, 'id')
    },
    themelessCount() {
      return (_.get(this.groupedContents, 'themeless.none', []) || []).length
    },
    themedCount() {
      return Object.keys(_.get(this.groupedContents, 'themed', {}) || {}).length
    },
    groupedContents() {
      const themed = _.groupBy(this.filteredContents, (content) => {
        return content.theme_title || 'none'
      })

      const themeless = _.pick(themed, 'none')
      const removedNoneTheme = _.unset(themed, 'none')

      return {
        themed,
        themeless
      }
    },
    filteredContents() {
      const search = this._.toLower(this._.deburr(this.searchInput))
      const type = this.type
      const contents = this.contents

      return contents.filter(
        (content) => {
          const content_type = this._.get(content, 'type')
          const conditions = []
          const ors = []
          
          /**
           * Get takes the title prop if it exists from our content object
           * Deburr removes any diacritics and accentuation
           * To lower makes everything lowercase
           */
          const content_title = this._.toLower(this._.deburr(this._.get(content, 'title', '')))
          const topic_title = this._.toLower(this._.deburr(this._.get(content, 'topic.title')))

          if (!this.isEmpty(search)) {
            const regex = ilike(search)
            const regexTestTitle = regex.test(content_title)
            const regexTestTopic = regex.test(topic_title)
            ors.push(regexTestTopic)
            conditions.push(regexTestTitle)
          }
          conditions.push(content_type === type)

          return conditions.every(condition => condition === true) || ors.some(condition => condition === true)
        }
      )
    }
  },
  methods: {
    forceThemeCollapseUpdate(theme) {
      this.forceUpdateVsCollapseItemHeight(
        this.getCollapseElement(theme)
      )
    },
    getCollapseElement(theme) {
      const key = `collapse_${theme}`
      return this.$refs[key]
    },
    forceUpdateVsCollapseItemHeight(ref) {
      let element = _.isArray(ref) ? ref[0] : ref

      if (element && 'changeHeight' in element) {
        setTimeout(() => {
          element.changeHeight()
          const extraction = parseInt(element.maxHeight.split('px')[0])
          element.maxHeight = `${extraction + Math.floor(extraction * 0.3)}px`
        }, 100)
      }
    },
    toggleExpansion(content, theme) {
      const id = _.get(content, 'id')

      console.log('trying to expand...', content, theme)

      const position = this.expandedContents.findIndex((_id) => _id === id)
      if (position !== -1) {
        this.expandedContents.splice(position, 1)
      } else {
        this.expandedContents.push(id)
      }
    },
    isExpanded(content) {
      const id = _.get(content, 'id')
      return id ? this.expandedContents.includes(id) : false
    },
    getContents(classId) {
      this.service.getClassContents(classId).then(
        (response) => {
          if (_.isArray(response)) {
            this.contents = response
          }
        }
      )
    }
  },
  created() {
    this.service = ContentService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.getContents(this.id)
    }
  }
}
</script>

<style lang="scss">

// Import _content-bubble-icon
@import "@/assets/scss/content/bubbleIcon";

// LAYOUT STYLE
._content-list {
  display: grid;
  gap: 1rem;

  & .__top {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    @apply row-start-1;
    @apply justify-between;
    @apply items-center;
  }

  & .__bottom {
    @apply row-start-2;

    & .__bottom-grid {
      display: grid;
      & .__filters {
        @apply col-start-1;
      }
      & .__list {
        @apply col-start-1;
      }
    }
  }
}

// INDIVIDUAL CONTENT GRID CARD STYLES
._content-list .__bottom .__bottom-grid .__list {
  @apply flex;
  @apply flex-col;
  @apply gap-2;
  --background-invert: theme('colors.grey-light');
  --background-normal: rgba(0,0,0,0);

  // & .vs-collapse-item:hover {
  //   border-color: $primary;
  //   border-style: solid;
  //   border-radius: 0.5rem;
  //   border-width: 0 0 0 0.25rem;
  // }

  & .vs-collapse-item--header {
    padding: 0px;
    --background-invert: rgba(0,0,0,0);
  }

  & .__themed {
    & .__instructions-html {
      max-width: 68vw !important;
    }
  }
}
</style>